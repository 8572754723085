import React, { FunctionComponent, useEffect, useState } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
  getChildAccount,
  getChildAccounts,
  subToChildAccount,
} from "../services/firebase";
import { childAccountsAtom, contextChildAccountAtom } from "../state/atoms/app";
import {
  showInviteChildToDownloadAppModalAtom,
  showUnlinkChildAccountModalAtom,
  showLinkChildAccountOTPModalAtom,
} from "../state/atoms/ui";
import { ChildAccount } from "../types/account";

import balanceIcon from "../images/balance-icon.svg";
import savingsIcon from "../images/savings-icon.svg";
import disconnectIcon from "../images/disconnect-icon.svg";
import successCircleIcon from "../images/success-circle.svg";
import { getBankDetailsById } from "../services/banks";
import { loggedInUserAtom } from "../state/atoms/auth";

const ChildAccountCard: FunctionComponent<{ childAccount: ChildAccount }> = ({
  childAccount,
}) => {
  const loggedInUser = useRecoilValue(loggedInUserAtom);
  const setShowInviteChildToDownloadAppModal = useSetRecoilState(
    showInviteChildToDownloadAppModalAtom
  );
  const setShowLinkChildAccountOTPModalAtom = useSetRecoilState(
    showLinkChildAccountOTPModalAtom
  );
  const [contextChildAccount, setContextChildAccount] = useRecoilState(
    contextChildAccountAtom
  );
  const { bankAccount } = contextChildAccount || {};
  const setChildAccounts = useSetRecoilState(childAccountsAtom);

  const setShowUnlinkChildAccountModalAtom = useSetRecoilState(
    showUnlinkChildAccountModalAtom
  );

  const [bankName, setBankName] = useState("");

  const {
    id,
    isVerified,
    childNameByParent,
    childNameByChild,
    firstName,
    lastName,
  } = childAccount;
  const accountTitle = isVerified
    ? firstName && lastName
      ? `החשבון של ${firstName} ${lastName}`
      : "חשבון ללא שם"
    : `חשבון ${
        (childNameByParent && `של ${childNameByParent}`) ||
        (childNameByChild && `של ${childNameByChild}`) ||
        "ללא שם"
      }`;

  useEffect(() => {
    if (bankAccount?.bankId) {
      const { name } = getBankDetailsById(bankAccount?.bankId);
      setBankName(name);
    }
  }, [bankAccount]);

  useEffect(() => {
    if (loggedInUser?.uid && id) {
      const unsub = subToChildAccount(id, async () => {
        console.log("Sub to child account fired");
        const childAccounts = await getChildAccounts(loggedInUser.uid);
        if (
          childAccounts.find((ac) => {
            return ac.id === id;
          })?.isVerified
        ) {
          setShowLinkChildAccountOTPModalAtom(false);
        }

        setChildAccounts(childAccounts);
      });

      return () => unsub();
    }
  }, [id]);

  const accountSubtitle = `בנק ${bankName}, ${bankAccount?.bankBranchId}-${bankAccount?.accountId}`;

  return (
    <div className="card card-body border border-brand-secondary child-account-card mt-1 p-0">
      <div className="bg-light">
        <div className="row p-2">
          <div className="d-none d-lg-flex col-auto">
            <div
              className={`${
                !isVerified ? "bg-warning" : "bg-success"
              } circle mt-1 w-1rem h-1rem`}
            >
              &nbsp;
            </div>
          </div>
          <div className="col-7 pr-lg-0">
            <div>
              <h4 className="mb-0">{accountTitle}</h4>
            </div>
            <div>
              <h6 className="mb-0">{accountSubtitle}</h6>
            </div>
          </div>
          <div className="col">
            <div className="d-flex float-left">
              <div
                className="text-center cursor-pointer"
                role="button"
                onClick={() => {
                  setShowUnlinkChildAccountModalAtom(true);
                }}
              >
                <img src={disconnectIcon} alt="disconnect" />
                <div className="text-brand-red">התנתק</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {!isVerified ? (
        <>
          <div className="p-2">
            <div className="row">
              <div className="col px-4">מה השלבים הבאים?</div>
            </div>
            <div className="row my-0 mx-2 p-0 py-2 border border-top-0 border-start-0 border-end-0">
              <div className="col p-0">
                <div className="d-flex align-items-center">
                  <img
                    className="w-2rem h-2rem m-0 ml-2 text-white text-center rounded line-height-1 d-none d-md-block"
                    src={successCircleIcon}
                    alt="success-circle"
                  />
                  <span className="my-auto">
                    חשבון הבנק ב{bankName} קושר לג&apos;וניור
                  </span>
                </div>
              </div>
            </div>
            <div className="row my-0 mx-2 p-0 py-2 border border-top-0 border-start-0 border-end-0">
              <div className="col p-0">
                <div className="d-flex align-items-center">
                  <h2 className="w-2rem h-2rem m-0 ml-2 bg-brand-secondary text-white text-center rounded line-height-1 d-none d-md-block">
                    2
                  </h2>
                  <span className="my-auto">
                    שליחת לינק לילדך להורדת אפליקציית ג&apos;וניור
                  </span>
                </div>
              </div>
              <div className="col-auto pl-0">
                <div className="h-100 d-flex align-items-center">
                  <button
                    className="btn border-brand-primary bg-brand-primary text-white w-7rem py-0"
                    onClick={async () => {
                      if (id) {
                        const childAccount = await getChildAccount(id);
                        setContextChildAccount(childAccount);
                        setShowInviteChildToDownloadAppModal(true);
                      }
                    }}
                  >
                    <small>שליחת זימון</small>
                  </button>
                </div>
              </div>
            </div>
            <div className="row my-0 mx-2 p-0 py-2">
              <div className="col p-0">
                <div className="d-flex align-items-center">
                  <h2 className="w-2rem h-2rem m-0 ml-2 bg-brand-secondary text-white text-center rounded line-height-1 d-none d-md-block">
                    3
                  </h2>
                  <span className="my-auto">הפעלת אפליקציה על ידי ילדך</span>
                </div>
              </div>
              <div className="col-auto pl-0">
                <div className="h-100 d-flex align-items-center">
                  <button
                    className="btn border-brand-primary text-brand-primary w-7rem py-0"
                    onClick={async () => {
                      if (id) {
                        const childAccount = await getChildAccount(id);
                        setContextChildAccount(childAccount);
                        setShowLinkChildAccountOTPModalAtom(true);
                      }
                    }}
                  >
                    <small>קוד קישור לאפליקציה</small>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="row p-2">
            <div className="col p-2 text-center">
              <div>
                <img
                  src={balanceIcon}
                  style={{ marginTop: "0.5rem" }}
                  alt="יתרת חשבון"
                />
              </div>
              <h2 className="mt-2 mb-0">₪134</h2>
              <small className="text-muted">יתרת חשבון</small>
            </div>
            <div className="col p-2 text-center">
              <div>
                <img src={savingsIcon} alt="חסכון" />
              </div>
              <h2 className="mt-2 mb-0">₪0</h2>
              <small className="text-muted">חסכון</small>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default ChildAccountCard;
