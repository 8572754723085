import React, { FunctionComponent } from "react";

import { Link } from "react-router-dom";

const PageNotFound: FunctionComponent = () => {
  return (
    <div className="p-4">
      <header>
        <h1 className="text-center text-lg-right">דף זה לא נמצא :(</h1>
      </header>
      <Link to="/" className="btn border-0 bg-white text-brand-primary">
        חזרה לדף הבית
      </Link>
    </div>
  );
};

export default PageNotFound;
