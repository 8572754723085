import { atom } from "recoil";

import { AccountDetails, ChildAccount } from "../../types/account";
import { ErrorModalData, SharePeriods } from "../../types/ui";

export const accountDataSharePeriodAtom = atom<SharePeriods | null>({
  key: "accountDataSharePeriodAtom",
  default: null,
});

export const accountDetailsAtom = atom<AccountDetails | null>({
  key: "accountDetailsAtom",
  default: null,
});

export const childAccountsAtom = atom<ChildAccount[]>({
  key: "childAccountsAtom",
  default: [],
});

export const contextChildAccountAtom = atom<ChildAccount | null>({
  key: "contextChildAccountAtom",
  default: null,
});

export const errorModalDataAtom = atom<ErrorModalData>({
  key: "errorModalDataAtom",
  default: {
    title: "מצטערים, ארעה שגיאה",
    description: "",
  },
});
