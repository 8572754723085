import { BANKS } from "../types/account";

export const getBankDetailsById = (
  bankId: BANKS
): { url: string; name: string } => {
  let url = "";
  let name = "";

  switch (bankId) {
    case BANKS.DISCOUNT:
      name = "דיסקונט";
      url = "https://start.telebank.co.il/login/#/LOGIN_PAGE";
      break;
    case BANKS.YERUSHALAIM:
      name = "ירושלים";
      url = "https://services.bankjerusalem.co.il/Pages/Login.aspx";
      break;
    case BANKS.MIZRACHI:
      name = "מזרחי-טפחות";
      url =
        "https://www.mizrahi-tefahot.co.il/login/bv5x83ut/index.html#/auth-page-he?aspxerrorpath=%2Flogin%2Flogin2MTO.aspx";
      break;
    case BANKS.POALIM:
      name = "הפועלים";
      url = "https://www.bankhapoalim.co.il/he/login";
      break;
    case BANKS.FIBI:
      name = "הבינלאומי הראשון";
      url = "https://www.fibi.co.il/wps/portal/";
      break;
    case BANKS.IGUD:
      name = "איגוד";
      url = "https://hb.unionbank.co.il/";
      break;
    case BANKS.LEUMI:
      name = "לאומי";
      url = "https://hb2.bankleumi.co.il/";
      break;
    case BANKS.MASAD:
      name = "מסד";
      url = "https://www.bankmassad.co.il/wps/portal/";
      break;
    case BANKS.OTSAR:
      name = "אוצר החייל";
      url = "https://www.bankotsar.co.il/wps/portal/FibiMenu/Marketing/Private";
      break;
    case BANKS.YAHAV:
      name = "יהב";
      url = "https://www.bank-yahav.co.il/";
      break;
    default:
      name = "דיסקונט";
      url = "https://start.telebank.co.il/login/#/LOGIN_PAGE";
      break;
  }
  return { url, name };
};
