/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FunctionComponent } from "react";

import loader from "../images/loader.gif";

const Loading: FunctionComponent<{
  size?: number;
  opacity?: number;
}> = ({ size = 5, opacity = 1 }) => {
  return (
    <img
      src={loader}
      style={{ width: size + "rem", height: size + "rem", opacity }}
      alt="טוענים..."
    />
  );
};

export default Loading;
