import React, { FunctionComponent, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";

import { loggedInUserAtom } from "../state/atoms/auth";
import {
  showErrorModalAtom,
  showLinkChildAccountBankSelectionModalAtom,
  showSignUpAlmostThereModalAtom,
} from "../state/atoms/ui";
import { childAccountsAtom, errorModalDataAtom } from "../state/atoms/app";

import { logger } from "../services/logger";
import { signUserOut } from "../services/firebase";

import LinkChildAccountBankSelectionModal from "./modals/LinkChildAccountBankSelectionModal";
import LinkChildAccountBankConsentModal from "./modals/LinkChildAccountBankConsentModal";
import LinkChildAccountOTPModal from "./modals/LinkChildAccountOTPModal";
import InviteChildToDownloadAppModal from "./modals/InviteChildToDownloadAppModal";
import SignUpAlmostThereModal from "./modals/SignUpAlmostThereModal";
import ErrorModal from "./modals/ErrorModal";
import ChildAccountCard from "./ChildAccountCard";
import UnlinkChildAccountModal from "./modals/UnlinkChildAccoountModal";

const Lobby: FunctionComponent = () => {
  const history = useHistory();
  const loggedInUser = useRecoilValue(loggedInUserAtom);

  if (!loggedInUser) {
    return <></>;
  }

  const { name, emailVerified } = loggedInUser;

  const setShowSignUpAlmostThereModal = useSetRecoilState(
    showSignUpAlmostThereModalAtom
  );
  const childAccounts = useRecoilValue(childAccountsAtom);

  const setShowLinkChildAccountBankSelectionModal = useSetRecoilState(
    showLinkChildAccountBankSelectionModalAtom
  );

  const setErrorModalData = useSetRecoilState(errorModalDataAtom);
  const setShowErrorModal = useSetRecoilState(showErrorModalAtom);
  useEffect(() => {
    setShowSignUpAlmostThereModal(!emailVerified);
  }, [loggedInUser]);

  const signOut = async () => {
    try {
      history.push("/");
      await signUserOut();
    } catch (err) {
      const { code } = err as { code: string };
      // TODO: Catch specific errors
      logger.error("Sign out error", code);
    }
  };

  return (
    <div className="p-4">
      <header className="row">
        <div className="col">
          <h1>היי, {name?.split(" ")[0] || "משתמש יקר"}!</h1>
        </div>
        <div className="col">
          <div className="d-none d-lg-block">
            <button
              className="float-left btn border-brand-primary bg-white text-brand-primary btn-lg m-1"
              onClick={signOut}
            >
              יציאה מהחשבון
            </button>
          </div>
          <div className="d-block d-lg-none">
            <button
              className="float-left btn border-brand-primary bg-white text-brand-primary btn-md m-1"
              onClick={signOut}
            >
              <i className="fa fa-sign-out rotate-180" />
            </button>
          </div>
        </div>
      </header>
      <div className="mt-5 mx-1">
        <div className="row">
          {childAccounts.length > 0 && (
            <div className="col-12 col-lg-6">
              {childAccounts.map((childAccount) => (
                <div className="row" key={`account-card-${childAccount.id}`}>
                  <ChildAccountCard childAccount={childAccount} />
                </div>
              ))}
            </div>
          )}
          <div className="col p-0 p-md-1">
            <button
              className="card card-body child-account-add-btn m-auto m-lg-0 mt-1 border-dashed text-brand-primary"
              onClick={() => {
                if (childAccounts.length) {
                  setErrorModalData({
                    title:
                      "מצטערים, כרגע לא ניתן לחבר יותר מילד אחד, אנחנו עובדים על זה...",
                    description:
                      "ניידע אותך במייל ברגע שניתן יהיה לחבר ילד נוסף",
                  });
                  return setShowErrorModal(true);
                }
                setShowLinkChildAccountBankSelectionModal(true);
              }}
            >
              <div className="w-100 text-center">
                <div className="fa-stack text-brand-primary mb-1">
                  <i className="far fa-circle fa-stack-2x" />
                  <i className="fas fa-plus fa-stack-1x" />
                </div>
                <h4 className="text-brand-primary">קשר חשבון ילד</h4>
              </div>
              <div className="w-100 text-center text-lg-start">
                <small className="text-muted">
                  על מנת שנוכל להציג את המידע בחשבון, אנחנו צריכים אישור גישה
                  לחשבון
                </small>
              </div>
            </button>
          </div>
        </div>
      </div>
      <LinkChildAccountBankSelectionModal />
      <LinkChildAccountBankConsentModal />
      <LinkChildAccountOTPModal />
      <InviteChildToDownloadAppModal />
      <UnlinkChildAccountModal />
      <SignUpAlmostThereModal />
      <ErrorModal />
    </div>
  );
};

export default Lobby;
