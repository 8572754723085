/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FunctionComponent, useEffect, useState } from "react";
import { useRecoilState, useSetRecoilState, useRecoilValue } from "recoil";
import dayjs from "dayjs";

import { addChildAccount, getChildAccounts } from "../../services/firebase";
import {
  accountDataSharePeriodAtom,
  accountDetailsAtom,
  childAccountsAtom,
  contextChildAccountAtom,
} from "../../state/atoms/app";
import { loggedInUserAtom } from "../../state/atoms/auth";
import {
  showLinkChildAccountBankConsentModalAtom,
  showInviteChildToDownloadAppModalAtom,
} from "../../state/atoms/ui";
import Loading from "../Loading";

import moneyIcon from "../../images/money.svg";
import transactionsIcon from "../../images/transactions.svg";
import cardIcon from "../../images/card.svg";
import paperMoneyIcon from "../../images/paper-money.svg";
import calendarIcon from "../../images/calendar.svg";
import { SharePeriods } from "../../types/ui";
import { getBankDetailsById } from "../../services/banks";

const LinkChildAccountBankConsentModal: FunctionComponent = () => {
  const loggedInUser = useRecoilValue(loggedInUserAtom);
  const [showModal, setShowModal] = useRecoilState(
    showLinkChildAccountBankConsentModalAtom
  );

  const accountDetails = useRecoilValue(accountDetailsAtom);

  const setShowInviteChildToDownloadAppModal = useSetRecoilState(
    showInviteChildToDownloadAppModalAtom
  );
  const setContextChildAccount = useSetRecoilState(contextChildAccountAtom);
  const setChildAccounts = useSetRecoilState(childAccountsAtom);

  const [loading, setLoading] = useState(false);
  const [bankURL, setBankURL] = useState("");
  const [sharePeriod, setSharePeriod] = useRecoilState(
    accountDataSharePeriodAtom
  );
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [shareUntilDate, setShareUntilDate] = useState<string>(
    dayjs().add(1, "year").format("YYYY-MM-DD")
  );
  const [canSubmit, setCanSubmit] = useState(false);

  // Show date picker effect
  useEffect(() => {
    if (sharePeriod !== SharePeriods.CUSTOM) {
      setShowDatePicker(false);
    } else {
      setShowDatePicker(true);
    }
  }, [sharePeriod]);

  useEffect(() => {
    if (accountDetails?.bankId) {
      const { url } = getBankDetailsById(accountDetails.bankId);
      setBankURL(url);
    }
  }, [accountDetails]);

  // Set share until date effect
  useEffect(() => {
    switch (sharePeriod) {
      case SharePeriods.ONE_MONTH:
        setShareUntilDate(dayjs().add(1, "month").format("YYYY-MM-DD"));
        break;
      case SharePeriods.THREE_MONTHS:
        setShareUntilDate(dayjs().add(3, "month").format("YYYY-MM-DD"));
        break;
      case SharePeriods.SIX_MONTHS:
        setShareUntilDate(dayjs().add(6, "month").format("YYYY-MM-DD"));
        break;
      default:
      case SharePeriods.CUSTOM:
        break;
    }
  }, [sharePeriod]);

  const onClose = () => {
    setShowModal(false);
  };

  if (!loggedInUser) return <></>;

  const onSubmit = async () => {
    if (canSubmit && accountDetails) {
      const { uid } = loggedInUser;
      setLoading(true);
      await addChildAccount(uid, accountDetails);
      const childAccounts = await getChildAccounts(uid);
      setChildAccounts(childAccounts);
      setContextChildAccount(childAccounts[childAccounts.length - 1] || null);
      setLoading(false);
      setShowModal(false);
      setShowInviteChildToDownloadAppModal(true);
    }
  };

  useEffect(() => {
    setCanSubmit(sharePeriod !== null);
  }, [sharePeriod]);

  return (
    <div
      className={`modal ${showModal ? "show" : ""} fade bg-black bg-opacity-50`}
      role="button"
      onClick={onClose}
    >
      <div
        className="modal-dialog modal-dialog-centered"
        role="button"
        onClick={(evt) => {
          evt.stopPropagation();
        }}
      >
        <div className="modal-content">
          <div className="form-group">
            <div className="modal-header p-4">
              <h4 className="modal-title font-weight-bold">
                קישור חשבון הבנק לחשבון ג&apos;וניור
              </h4>
            </div>
            <div className="modal-body px-4 py-0">
              {loading ? (
                <div className="text-center py-3">
                  <Loading size={10} opacity={0.6} />
                </div>
              ) : (
                <>
                  <p>
                    עכשיו נקשר אותך לאתר הבנק שלך על מנת שתאשר לנו הרשאה למידע
                    הבא על החשבון שפתחת עבור ילדך. ללא הרשאה זאת לא נוכל להציג
                    מידע באפליקציה.
                  </p>
                  <div className="my-5 row text-center">
                    <div className="col-6 col-md-3 py-2">
                      <div className="fa-stack text-brand-secondary mb-1 circle">
                        <img src={paperMoneyIcon} alt="יתרות" />
                      </div>
                      <div className="text-small">יתרות בחשבון עו&quot;ש</div>
                    </div>
                    <div className="col-6 col-md-3 py-2">
                      <div className="fa-stack text-brand-secondary mb-1">
                        <img src={transactionsIcon} alt="תנועות" />
                      </div>
                      <div className="text-small">תנועות בחשבון עו&quot;ש</div>
                    </div>

                    <div className="col-6 col-md-3 py-2">
                      <div className="fa-stack text-brand-secondary mb-1">
                        <img src={cardIcon} alt="מידע על כרטיסי חיוב" />
                      </div>
                      <div className="text-small">מידע על כרטיסי חיוב</div>
                    </div>
                    <div className="col-6 col-md-3 py-2">
                      <div className="fa-stack text-brand-secondary mb-1">
                        <img src={moneyIcon} alt="חסכונות ופקדונות" />
                      </div>
                      <div className="text-small">חסכונות ופקדונות</div>
                    </div>
                  </div>
                  <p>למשך כמה זמן תרצה לשתף את המידע?</p>
                  <div className="row mb-2 p-1 position-relative">
                    <div
                      className={`rounded cursor-pointer border text-center ${
                        sharePeriod === SharePeriods.ONE_MONTH
                          ? "border-brand-secondary bg-light text-muted opacity-1"
                          : "border-brand-primary bg-white text-brand-primary"
                      } col-12 col-md mb-1 ml-1 py-05 px-05`}
                      role="button"
                      onClick={() => {
                        setSharePeriod(SharePeriods.ONE_MONTH);
                      }}
                    >
                      חודש
                    </div>
                    <div
                      className={`rounded cursor-pointer border text-center ${
                        sharePeriod === SharePeriods.THREE_MONTHS
                          ? "border-brand-secondary bg-light text-muted opacity-1"
                          : "border-brand-primary bg-white text-brand-primary"
                      } col-12 col-md mb-1 ml-1 py-05 px-05`}
                      role="button"
                      onClick={() => {
                        setSharePeriod(SharePeriods.THREE_MONTHS);
                      }}
                    >
                      3 חודשים
                    </div>
                    <div
                      className={`rounded cursor-pointer border text-center ${
                        sharePeriod === SharePeriods.SIX_MONTHS
                          ? "border-brand-secondary bg-light text-muted opacity-1"
                          : "border-brand-primary bg-white text-brand-primary"
                      } col-12 col-md mb-1 ml-1 py-05 px-05`}
                      role="button"
                      onClick={() => {
                        setSharePeriod(SharePeriods.SIX_MONTHS);
                      }}
                    >
                      6 חודשים
                    </div>
                    <div
                      className={`rounded cursor-pointer border text-center ${
                        sharePeriod === SharePeriods.CUSTOM
                          ? "border-brand-secondary bg-light text-muted opacity-1"
                          : "border-brand-primary bg-white text-brand-primary"
                      } col-12 col-md-4 mb-1 py-05 px-1 px-lg-05`}
                      role="button"
                      onClick={() => {
                        setSharePeriod(SharePeriods.CUSTOM);
                      }}
                    >
                      {showDatePicker ? (
                        <input
                          className="form-control p-0"
                          type="date"
                          style={{
                            backgroundColor: "unset",
                          }}
                          value={shareUntilDate}
                          min="2022-01-01"
                          max="2030-12-31"
                          onChange={(e) =>
                            setShareUntilDate(
                              dayjs(e.target.value).format("YYYY-MM-DD")
                            )
                          }
                        />
                      ) : (
                        <div className="d-flex justify-content-between">
                          <div className="ml-1">עד תאריך</div>
                          <img src={calendarIcon} alt="עד תאריך" />
                        </div>
                      )}
                    </div>
                  </div>

                  <p className="mb-0">
                    <b>הפרטים שלך תמיד מוגנים ופרטיים.</b> אנחנו לא יכולים לראות
                    את פרטי ההתחברות שלך או כל מידע בנקאי אחר.
                  </p>
                </>
              )}
            </div>
            <div className="modal-footer p-4 d-block text-center">
              <a
                href={bankURL}
                target="_blank"
                rel="noreferrer"
                className={`btn text-white bg-brand-primary w-75 ${
                  canSubmit ? "" : "disabled"
                }`}
                onClick={onSubmit}
              >
                מעבר לאתר הבנק
              </a>
              <button
                className="btn text-brand-primary d-block m-auto"
                onClick={onClose}
              >
                אמשיך מאוחר יותר
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LinkChildAccountBankConsentModal;
