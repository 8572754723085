import { initializeApp } from "firebase/app";
import {
  Timestamp,
  getFirestore,
  connectFirestoreEmulator,
  collection,
  doc,
  getDoc,
  getDocs,
  setDoc,
  deleteDoc,
  where,
  query,
  DocumentData,
  DocumentReference,
  DocumentSnapshot,
  Unsubscribe,
  onSnapshot,
} from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";
import {
  connectAuthEmulator,
  NextOrObserver,
  User,
  UserCredential,
  getAuth,
  onAuthStateChanged,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  Unsubscribe as AuthUnsubscribe,
  sendEmailVerification,
} from "firebase/auth";

import { NODE_ENV, REACT_APP_DOMAIN } from "../services/config";
import { logger } from "./logger";
import { AppUser } from "../types/user";
import { AccountDetails, ChildAccount } from "../types/account";

const firebaseConfig = {
  apiKey: "AIzaSyDL0GRUNypTbe4dRlCLIbxICJWHF3LpxaE",
  authDomain: "junior-app-338912.firebaseapp.com",
  projectId: "junior-app-338912",
  storageBucket: "junior-app-338912.appspot.com",
  messagingSenderId: "27720158879",
  appId: "1:27720158879:web:7667cc8a3b622c0c129caa",
  measurementId: "G-TCDPDB4D2H",
};

// Initialize Firebase
export const firebaseApp = initializeApp(firebaseConfig);
export const firebaseAuth = getAuth(firebaseApp);
export const firebaseAnalytics = getAnalytics(firebaseApp);
export const firebaseFirestore = getFirestore(firebaseApp);

if (NODE_ENV !== "production") {
  logger.warn(`Running in ${NODE_ENV} environment`);
  connectAuthEmulator(firebaseAuth, "http://localhost:9099");
  connectFirestoreEmulator(firebaseFirestore, "localhost", 5001);
}

// Auth
export const signUserUp = (
  email: string,
  password: string
): Promise<UserCredential> =>
  createUserWithEmailAndPassword(firebaseAuth, email, password);

export const signUserIn = (
  email: string,
  password: string
): Promise<UserCredential> =>
  signInWithEmailAndPassword(firebaseAuth, email, password);

export const signUserOut = (): Promise<void> => signOut(firebaseAuth);

export const sendUserEmailVerification = (user: User): Promise<void> =>
  sendEmailVerification(user, {
    url: `${REACT_APP_DOMAIN}/sign-in?showEmailVerifiedModal=true`,
  });

export const onAuthStateChangedHook = (
  observer: NextOrObserver<User>
): AuthUnsubscribe => onAuthStateChanged(firebaseAuth, observer);

// Firestore
const usersCollection = collection(firebaseFirestore, "users");
const childAccountsCollection = collection(firebaseFirestore, "childAccounts");

const removeEmpty = <T>(obj: T): Record<string, Partial<T>> => {
  return Object.fromEntries(
    Object.entries(obj).filter(([, v]) => v !== undefined)
  );
};

// User utils
export const upsertUser = (user: AppUser): Promise<void> => {
  const { uid, name, phone, email, photoURL } = user;
  const docToCreate = doc(usersCollection, uid);
  return setDoc(
    docToCreate,
    removeEmpty<AppUser>({
      uid,
      name,
      phone,
      email,
      photoURL,
    }),
    { merge: true }
  );
};

export const getUser = async (uid: string): Promise<AppUser> => {
  const user = (await getDoc(doc(usersCollection, uid))).data();
  if (user) {
    const { name, phone, email, photoURL } = user;
    return { uid, name, phone, email, photoURL };
  }
  return { uid };
};

// Child account utils
export const addChildAccount = async (
  uid: string,
  bankAccount: AccountDetails
): Promise<void> => {
  return setDoc(
    doc(childAccountsCollection),
    removeEmpty<ChildAccount>({
      firstParentUid: uid,
      createdAt: Timestamp.now(),
      updatedAt: Timestamp.now(),
      isVerified: false,
      verificationCode: generateVerificationCode(),
      bankAccount,
    }),
    { merge: true }
  );
};

export const getChildAccounts = async (
  uid: string
): Promise<ChildAccount[]> => {
  const childAccounts = await getDocs(
    query(childAccountsCollection, where("firstParentUid", "==", uid))
  );
  const retAccArr: ChildAccount[] = [];
  childAccounts.forEach((acc) =>
    retAccArr.push({ ...(acc.data() as ChildAccount), id: acc.id })
  );
  return retAccArr.sort(
    (a, b) =>
      (a.createdAt?.toMillis() || Infinity) -
      (b.createdAt?.toMillis() || Infinity)
  );
};

export const getChildAccount = async (
  accId: string
): Promise<ChildAccount | null> => {
  const acc = await getDoc(doc(childAccountsCollection, accId));
  return acc.exists() ? { ...(acc.data() as ChildAccount), id: acc.id } : null;
};

export const subToChildAccount = (
  id: string,
  observer: (snapshot: DocumentSnapshot<DocumentData>) => void
): Unsubscribe => {
  const docRef: DocumentReference<DocumentData> = doc(
    childAccountsCollection,
    id
  );

  return onSnapshot<DocumentData>(
    docRef,
    { includeMetadataChanges: false },
    observer
  );
};

export const updateChildAccountVerificationCode = async (
  accId: string
): Promise<void> => {
  const docToCreate = doc(childAccountsCollection, accId);
  return setDoc(
    docToCreate,
    { verificationCode: generateVerificationCode() },
    { merge: true }
  );
};

export const setChildAccountVerifiedState = async (
  accId: string,
  name: string
): Promise<void> => {
  const docToCreate = doc(childAccountsCollection, accId);
  return setDoc(
    docToCreate,
    { verifiedAt: Timestamp.now(), isVerified: true, firstName: name },
    { merge: true }
  );
};

export const generateVerificationCode = (): number =>
  Math.floor(100000 + Math.random() * 900000);

export const deleteChildren = async (uid: string): Promise<void> => {
  const childAccounts = await getDocs(
    query(childAccountsCollection, where("firstParentUid", "==", uid))
  );

  const childDocs: DocumentReference<DocumentData>[] = [];

  childAccounts.forEach((acc) => {
    childDocs.push(acc.ref);
  });

  for (let i = 0; i < childDocs.length; i++) {
    await deleteDoc(childDocs[i]);
  }
};
