import React, { FunctionComponent, useEffect } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";

import {
  showSignUpModalAtom,
  showEmailVerifiedSignInAtom,
} from "../state/atoms/ui";
import SignUpModal from "./modals/SignUpModal";
import { Link, useHistory, useLocation } from "react-router-dom";

import { loggedInUserAtom } from "../state/atoms/auth";

import { signUserOut } from "../services/firebase";
import { logger } from "../services/logger";

const Welcome: FunctionComponent = () => {
  const location = useLocation();
  const history = useHistory();
  const loggedInUser = useRecoilValue(loggedInUserAtom);
  const showSignUpModal = useSetRecoilState(showSignUpModalAtom);
  const setShowEmailVerifiedModal = useSetRecoilState(
    showEmailVerifiedSignInAtom
  );

  const signOut = async () => {
    try {
      await signUserOut();
    } catch (err) {
      const { code } = err as { code: string };
      // TODO: Catch specific errors
      logger.error("Sign out error", code);
    }
  };

  useEffect(() => {
    const redirectToEmailVerification = async () => {
      const queryParams = new URLSearchParams(location.search);

      if (queryParams.has("showEmailVerifiedModal")) {
        queryParams.delete("showEmailVerifiedModal");
        setShowEmailVerifiedModal(true);
        history.push("/sign-in");
      }
    };
    redirectToEmailVerification();
  }, []);

  return (
    <div className="p-4 h-100vh background-image">
      <div className="row">
        <div className="col-12 col-lg-6">
          <header>
            <h1 className="text-center text-lg-right">
              ברוכים הבאים לג&apos;וניור!
            </h1>
          </header>
        </div>
        <div className="col-12 col-lg-6">
          <div className="text-center text-lg-left">
            {loggedInUser ? (
              <>
                <div className="d-none d-lg-block">
                  <button
                    className="float-left btn btn-lg border-brand-primary bg-white text-brand-primary m-1"
                    onClick={signOut}
                  >
                    יציאה מהחשבון
                  </button>
                </div>
                <div className="d-block d-lg-none">
                  <button
                    className="float-left btn btn-md border-brand-primary bg-white text-brand-primary m-1"
                    onClick={signOut}
                  >
                    <i className="fa fa-sign-out rotate-180" />
                  </button>
                </div>
                <div className="d-none d-lg-block">
                  <Link
                    to="/lobby"
                    className="btn btn-lg bg-brand-primary text-white m-1 w-12rem"
                  >
                    חזרה ללובי
                  </Link>
                </div>
                <div className="d-block d-lg-none">
                  <Link
                    to="/lobby"
                    className="float-right btn btn-md border-brand-primary bg-brand-primary text-white m-1"
                  >
                    <i className="fa fa-home" />
                  </Link>
                </div>
              </>
            ) : (
              <>
                <button
                  className="btn bg-brand-primary text-white btn-lg m-1 w-12rem"
                  onClick={() => {
                    showSignUpModal(true);
                  }}
                >
                  יצירת משתמש
                </button>
                <Link
                  to="/sign-in"
                  className="btn border-brand-primary bg-white text-brand-primary btn-lg m-1 w-12rem"
                >
                  כניסת משתמש קיים
                </Link>
              </>
            )}
          </div>
        </div>
      </div>
      <SignUpModal />
    </div>
  );
};

export default Welcome;
