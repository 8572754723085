/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FunctionComponent } from "react";
import { useRecoilValue } from "recoil";

import { showSignUpAlmostThereModalAtom } from "../../state/atoms/ui";
import progressIcon from "../../images/progress.svg";

const SignUpAlmostThereModal: FunctionComponent = () => {
  const showModal = useRecoilValue(showSignUpAlmostThereModalAtom);

  return (
    <div
      className={`modal ${showModal ? "show" : ""} fade bg-black bg-opacity-50`}
    >
      <div
        className="modal-dialog modal-dialog-centered cursor-arrow"
        role="button"
        onClick={(evt) => {
          evt.stopPropagation();
        }}
      >
        <div className="modal-content">
          <div className="modal-body p-4 text-center">
            <div>
              <img src={progressIcon} alt="progress" />
            </div>
            <h4 className="mt-3 mb-0">
              כמעט שם... על מנת לסיים את התהליך יש להיכנס לתיבת האימייל וללחוץ
              על הלינק
            </h4>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUpAlmostThereModal;
