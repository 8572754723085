import { atom } from "recoil";

export const showSignUpModalAtom = atom<boolean>({
  key: "showSignUpModalAtom",
  default: false,
});

export const showSignUpAlmostThereModalAtom = atom<boolean>({
  key: "showSignUpAlmostThereModalAtom",
  default: false,
});

export const showSignInModalAtom = atom<boolean>({
  key: "showSignInModalAtom",
  default: false,
});

export const showEmailVerifiedSignInAtom = atom<boolean>({
  key: "showEmailVerifiedSignInAtom",
  default: false,
});

export const showLinkChildAccountBankSelectionModalAtom = atom<boolean>({
  key: "showLinkChildAccountBankSelectionModalAtom",
  default: false,
});

export const showLinkChildAccountBankConsentModalAtom = atom<boolean>({
  key: "showLinkChildAccountBankConsentModalAtom",
  default: false,
});

export const showLinkChildAccountOTPModalAtom = atom<boolean>({
  key: "showLinkChildAccountOTPModalAtom",
  default: false,
});

export const showInviteChildToDownloadAppModalAtom = atom<boolean>({
  key: "showInviteChildToDownloadAppModalAtom",
  default: false,
});

export const showUnlinkChildAccountModalAtom = atom<boolean>({
  key: "showUnlinkChildAccountModalAtom",
  default: false,
});

export const showErrorModalAtom = atom<boolean>({
  key: "showErrorModalAtom",
  default: false,
});
