/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FunctionComponent } from "react";
import { useRecoilValue, useRecoilState } from "recoil";
import { errorModalDataAtom } from "../../state/atoms/app";
import errorIcon from "../../images/error.svg";
import { showErrorModalAtom } from "../../state/atoms/ui";

const ErrorModal: FunctionComponent = () => {
  const [showModal, setShowModal] = useRecoilState(showErrorModalAtom);
  const errorModalData = useRecoilValue(errorModalDataAtom);

  const { title, description } = errorModalData;
  const onClose = () => {
    setShowModal(false);
  };

  return (
    <div
      className={`modal ${showModal ? "show" : ""} fade bg-black bg-opacity-50`}
      role="button"
      onClick={onClose}
    >
      <div
        className="modal-dialog modal-dialog-centered"
        role="button"
        onClick={(evt) => {
          evt.stopPropagation();
        }}
      >
        <div className="modal-content">
          <div className="form-group">
            <div className="modal-body p-4">
              <div className="text-center">
                <div className="fa-stack fa-2x">
                  <img src={errorIcon} alt="error" />
                </div>
                <h4 className="modal-title mt-3 mb-1 font-weight-bold">
                  {title}
                </h4>
              </div>

              <p className="mt-3 mb-0 text-center">{description}</p>
            </div>
            <div className="modal-footer p-4 d-block text-center">
              <button
                type="button"
                className="btn text-white bg-brand-primary w-75"
                onClick={() => {
                  setShowModal(false);
                }}
              >
                אוקיי, הבנתי
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ErrorModal;
