/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FunctionComponent, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useRecoilState, useSetRecoilState } from "recoil";

import { signUserIn, signUserOut } from "../services/firebase";
import { logger } from "../services/logger";
import { isValidEmail, isValidPassword } from "../services/utils";
import { loggedInUserAtom, signInFormDataAtom } from "../state/atoms/auth";
import { showEmailVerifiedSignInAtom } from "../state/atoms/ui";

const SignInModal: FunctionComponent = () => {
  const history = useHistory();
  const setLoggedInUser = useSetRecoilState(loggedInUserAtom);
  const [, setshowEmailVerifiedMessage] = useRecoilState(
    showEmailVerifiedSignInAtom
  );
  const [signInFormData, setSignInFormData] =
    useRecoilState(signInFormDataAtom);

  const { email, password } = signInFormData;

  const [canSubmit, setCanSubmit] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [errored, setErrored] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const onSubmit = async () => {
    if (!canSubmit || submitting) return;
    try {
      setSubmitting(true);
      setErrored(false);
      setErrorMsg("");
      await signUserIn(email.trim(), password);
      setSignInFormData({ email: "", password: "" });
      history.push("/lobby");
    } catch (err) {
      const { code } = err as { code: string };
      logger.error("Sign in error", code);
      setErrored(true);
      switch (code) {
        case "auth/user-not-found":
          setErrorMsg(
            "לא הצלחנו למצוא משתמש עם כתובת אימייל זו, האם היא הוקלדה נכון?"
          );
          break;
        default:
          setErrorMsg("הכניסה נכשלה, אנא ודא כי הזנת פרטים נכונים");
          break;
      }
    } finally {
      setSubmitting(false);
    }
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);

    if (queryParams.get("showEmailVerifiedModal") === "true") {
      signUserOut();
      setLoggedInUser(null);
      setshowEmailVerifiedMessage(true);
    }
  }, []);

  useEffect(() => {
    setCanSubmit(!!(isValidEmail(email) && isValidPassword(password)));
  }, [email, password]);

  return (
    <div className="sign-in-page">
      <form
        className="form-group"
        onSubmit={(evt) => {
          evt.preventDefault();
          onSubmit();
        }}
      >
        <div className="p-4 pb-0">
          <h3 className="mb-0">כניסה לחשבונך</h3>
          <h6 className="mb-0">על מנת לוודא שאלו אכן אתם עליכם להזדהות</h6>
        </div>
        <div className="p-4">
          <label htmlFor="email" className="mt-1">
            אימייל
          </label>
          <input
            id="email"
            className={`form-control ${errored ? "border-danger" : ""}`}
            type="email"
            autoComplete="email"
            value={email}
            onChange={(evt) =>
              setSignInFormData((previousState) => ({
                ...previousState,
                email: evt.target.value,
              }))
            }
          />
          <label htmlFor="password" className="mt-1">
            סיסמה
          </label>
          <input
            id="password"
            className={`form-control ${errored ? "border-danger" : ""}`}
            type="password"
            autoComplete="current-password"
            value={password}
            onChange={(evt) =>
              setSignInFormData((previousState) => ({
                ...previousState,
                password: evt.target.value,
              }))
            }
          />
          {errored && errorMsg && (
            <small className="text-danger">{errorMsg}</small>
          )}
        </div>
        <div className="row row flex-column-reverse flex-md-row m-0 mt-0 mt-md-1">
          <div className="col-12 col-md-6 px-4 text-center text-md-end">
            <Link
              to="/"
              className="btn border-0 bg-white text-brand-primary mt-1 mt-md-0"
            >
              חזרה לדף הבית
            </Link>
          </div>
          <div className="col-12 col-md-6 px-4 text-center text-md-start">
            <button
              type="submit"
              className="btn text-white bg-brand-primary"
              disabled={!canSubmit}
              onClick={onSubmit}
            >
              כניסה לחשבון
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default SignInModal;
