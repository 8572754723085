import { atom } from "recoil";

import {
  SignUpModalData,
  SignInModalData,
  KidActivationData,
} from "../../types/ui";
import { AppUser } from "../../types/user";

export const signUpFormDataAtom = atom<SignUpModalData>({
  key: "signUpFormDataAtom",
  default: {
    name: "",
    phone: "",
    email: "",
    password: "",
    passwordConfirm: "",
  },
});

export const signInFormDataAtom = atom<SignInModalData>({
  key: "signInFormDataAtom",
  default: {
    email: "",
    password: "",
  },
});

export const kidActivationFormData = atom<KidActivationData>({
  key: "kidActivationFormData",
  default: {
    name: "",
    activationCode: 0,
  },
});

export const loggedInUserAtom = atom<AppUser | null>({
  key: "loggedInUserAtom",
  default: null,
});

export const waitingForAuthAtom = atom<boolean>({
  key: "waitingForAuthAtom",
  default: true,
});
