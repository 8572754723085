import { Timestamp } from "@firebase/firestore";

// Date and time
export function timestampToDate(timestamp: Timestamp): string {
  const d = new Date(timestamp.seconds * 1000);
  return `${d.getDate()} ב${monthNumToText[d.getMonth()]} ${d.getFullYear()}`;
}

export function timestampToDashFormattedDate(timestamp: Timestamp): string {
  const d = new Date(timestamp.seconds * 1000);
  return `${d.getFullYear()}-${(d.getMonth() + 1)
    .toString()
    .padStart(2, "0")}-${d.getDate().toString().padStart(2, "0")}`;
}

export function timestampToTime(timestamp: Timestamp): string {
  const d = new Date(timestamp.seconds * 1000);
  return `${d.getHours().toString().padStart(2, "0")}:${d
    .getMinutes()
    .toString()
    .padStart(2, "0")}`;
}

export function timestampToWeekDay(timestamp: Timestamp): string {
  const d = new Date(timestamp.seconds * 1000);
  return `${weekDayToText[d.getDay()]}`;
}

export const weekDayToText = [
  "ראשון",
  "שני",
  "שלישי",
  "רביעי",
  "חמישי",
  "שישי",
  "שבת",
];

export const monthNumToText = [
  "ינואר",
  "פברואר",
  "מרץ",
  "אפריל",
  "מאי",
  "יוני",
  "יולי",
  "אוגוסט",
  "ספטמבר",
  "אוקטובר",
  "נובמבר",
  "דצמבר",
];

// Input validators
export const isValidDisplayName = (name: string | undefined): boolean => {
  if (!name) return false;

  const trimmedName = name.trim();
  return (
    !!trimmedName &&
    trimmedName.length > 3 &&
    trimmedName.split(" ").length >= 2 &&
    trimmedName.split(" ").every((subName) => subName.length > 1)
  );
};

export const isValidTzId = (id: string | undefined): boolean => {
  if (!id) return false;

  return (
    /\d{9}/.test(id) &&
    Array.from(id, Number).reduce((counter, digit, i) => {
      const step = digit * ((i % 2) + 1);
      return counter + (step > 9 ? step - 9 : step);
    }) %
      10 ===
      0
  );
};

export const isValidPhoneNumber = (
  phoneNumber: string | undefined
): boolean => {
  if (!phoneNumber) return false;

  return (
    (phoneNumber.length === 9 || phoneNumber.length === 10) &&
    phoneNumber.startsWith("0")
  );
};

export const isValidEmail = (email: string | undefined): boolean => {
  if (!email) return false;

  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email.toLowerCase());
};

export const isValidPassword = (password: string | undefined): boolean => {
  if (!password) return false;

  const re = new RegExp(
    "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
  );

  return re.test(password);
};

export const getQueryParams = (): URLSearchParams => {
  const search = window.location.search;
  const params = new URLSearchParams(search);
  return params;
};
