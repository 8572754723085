/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FunctionComponent } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import progressIcon from "../../images/progress.svg";
import { deleteChildren } from "../../services/firebase";
import { contextChildAccountAtom } from "../../state/atoms/app";
import { loggedInUserAtom } from "../../state/atoms/auth";
import { showUnlinkChildAccountModalAtom } from "../../state/atoms/ui";

const UnlinkChildAccountModal: FunctionComponent = () => {
  const loggedInUser = useRecoilValue(loggedInUserAtom);
  const [showModal, setShowModal] = useRecoilState(
    showUnlinkChildAccountModalAtom
  );

  const contextChildAccount = useRecoilValue(contextChildAccountAtom);
  const { bankAccount } = contextChildAccount || {};

  const onClose = () => {
    setShowModal(false);
  };

  if (!loggedInUser) {
    return <></>;
  }

  return (
    <div
      className={`modal ${showModal ? "show" : ""} fade bg-black bg-opacity-50`}
      role="button"
      onClick={onClose}
    >
      <div
        className="modal-dialog modal-dialog-centered"
        role="button"
        onClick={(evt) => {
          evt.stopPropagation();
        }}
      >
        <div className="modal-content">
          <div className="form-group">
            <div className="modal-body p-4">
              <div className="text-center">
                <div className="fa-stack fa-2x">
                  <img src={progressIcon} alt="error" />
                </div>
                <h4 className="modal-title mt-3 mb-1 font-weight-bold">
                  האם ברצונך לנתק את החשבון
                  <br />
                  המסתיים ב-{bankAccount?.accountId?.toString().substr(-3)}{" "}
                  בסניף {bankAccount?.bankBranchId} מג&apos;וניור?
                </h4>
              </div>

              <p className="mt-3 mb-0 text-center"></p>
            </div>
            <div className="modal-footer p-4 d-block text-center">
              <button
                type="button"
                className="btn btn-danger w-75"
                onClick={() => {
                  setShowModal(false);
                  deleteChildren(loggedInUser.uid)
                    .then(() => location.reload())
                    .catch(console.error);
                }}
              >
                כן, התנתק
              </button>
              <button
                className="btn text-brand-primary d-block m-auto"
                onClick={onClose}
              >
                לא, אל תנתק
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UnlinkChildAccountModal;
