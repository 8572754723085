/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FunctionComponent, useState } from "react";
import { QRCodeSVG } from "qrcode.react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { CopyToClipboard } from "react-copy-to-clipboard";

import { loggedInUserAtom } from "../../state/atoms/auth";
import { REACT_APP_DOMAIN } from "../../services/config";
import {
  showInviteChildToDownloadAppModalAtom,
  showLinkChildAccountOTPModalAtom,
} from "../../state/atoms/ui";

import progressIcon from "../../images/progress.svg";
import successIcon from "../../images/success.svg";
import { contextChildAccountAtom } from "../../state/atoms/app";
import { getBankDetailsById } from "../../services/banks";

const InviteChildToDownloadAppModal: FunctionComponent = () => {
  const loggedInUser = useRecoilValue(loggedInUserAtom);
  const [showModal, setShowModal] = useRecoilState(
    showInviteChildToDownloadAppModalAtom
  );
  const setShowLinkChildAccountOTPModal = useSetRecoilState(
    showLinkChildAccountOTPModalAtom
  );

  const contextChildAccount = useRecoilValue(contextChildAccountAtom);

  const { bankAccount } = contextChildAccount || {};

  const [isCopied, setIsCopied] = useState(false);

  const onClose = () => {
    setShowModal(false);
  };

  const notifyCopySuccess = () => {
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 2000);
  };

  const generateCopyLinkText = function () {
    return `
היי, 
כדי להתחיל להשתמש בחשבון הבנק החדש שנפתח לך
הורד את האפליקציה בלינק המצורף:
${REACT_APP_DOMAIN}/get-app`;
  };

  if (!loggedInUser || !bankAccount?.bankId) return <></>;

  const { name: bankName } = getBankDetailsById(bankAccount?.bankId);

  return (
    <div
      className={`modal ${showModal ? "show" : ""} fade bg-black bg-opacity-50`}
      role="button"
      onClick={onClose}
    >
      <div
        className="modal-dialog modal-dialog-centered"
        role="button"
        onClick={(evt) => {
          evt.stopPropagation();
        }}
      >
        <div className="modal-content">
          <div className="form-group">
            <div className="modal-body p-4">
              <div className="text-center">
                <div>
                  <img src={progressIcon} alt="progress" />
                </div>
                <h4 className="modal-title mt-3 mb-1 font-weight-bold">
                  חשבון המסתיים ב-
                  {bankAccount?.accountId?.toString().substr(-3)} בבנק{" "}
                  {bankName}, <br />
                  סניף {bankAccount?.bankBranchId} קושר בהצלחה
                </h4>
                <p className="mt-4 mb-0">בקש מילדך לסרוק את הברקוד או</p>
                <CopyToClipboard
                  text={generateCopyLinkText()}
                  onCopy={notifyCopySuccess}
                >
                  <p className="text-brand-primary cursor-pointer">
                    העתק הזמנה להורדת האפליקציה
                  </p>
                </CopyToClipboard>
              </div>
              <div className="text-center mt-4 rounded">
                <div className="row mt-1 px-4">
                  <div
                    className="col"
                    style={{
                      height: "150px",
                    }}
                  >
                    {isCopied ? (
                      <div>
                        <img src={successIcon} alt="success"></img>
                        <p>הלינק הועתק בהצלחה</p>
                      </div>
                    ) : (
                      <QRCodeSVG
                        value={`${REACT_APP_DOMAIN}/get-app`}
                        style={{
                          height: "150px",
                        }}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer p-4 pt-0 d-block text-center">
              <button
                type="button"
                className="btn text-white bg-brand-primary w-75"
                onClick={() => {
                  setShowLinkChildAccountOTPModal(true);
                  setShowModal(false);
                }}
              >
                האפליקציה הותקנה על הטלפון של הילד
              </button>
              <button
                className="btn text-brand-primary d-block m-auto"
                onClick={onClose}
              >
                אמשיך מאוחר יותר
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InviteChildToDownloadAppModal;
