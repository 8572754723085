import React, { FunctionComponent, useEffect } from "react";
import { RecoilRoot, useRecoilState, useSetRecoilState } from "recoil";
import { Router, Switch, Route, Redirect } from "react-router-dom";

import { history } from "./services/history";
import {
  getUser,
  getChildAccounts,
  onAuthStateChangedHook,
  deleteChildren,
} from "./services/firebase";
import { logger } from "./services/logger";
import { waitingForAuthAtom, loggedInUserAtom } from "./state/atoms/auth";
import { childAccountsAtom, contextChildAccountAtom } from "./state/atoms/app";

import Loading from "./components/Loading";
import Welcome from "./components/Welcome";
import Lobby from "./components/Lobby";
import SignIn from "./components/SignIn";
import PageNotFound from "./components/PageNotFound";

const WrappedApp: FunctionComponent = () => {
  const [waitingForAuth, setWaitingForAuth] =
    useRecoilState(waitingForAuthAtom);
  const [loggedInUser, setLoggedInUser] = useRecoilState(loggedInUserAtom);
  const setChildAccounts = useSetRecoilState(childAccountsAtom);
  const setContextChildAccount = useSetRecoilState(contextChildAccountAtom);
  const queryParams = new URLSearchParams(location.search);
  const clearChildren = queryParams.get("clearChildren");

  useEffect(() => {
    if (clearChildren && clearChildren === "true" && loggedInUser) {
      queryParams.delete("clearChildren");
      const uid = loggedInUser?.uid;
      deleteChildren(uid)
        .then(() => location.replace("/lobby"))
        .catch(console.error);
    }
  }, [clearChildren, loggedInUser]);

  useEffect(() => {
    onAuthStateChangedHook(async (user) => {
      try {
        setWaitingForAuth(true);
        if (user) {
          const { uid, emailVerified } = user;
          const { name, email, phone, photoURL } = await getUser(uid);
          setLoggedInUser({
            uid,
            name,
            email,
            emailVerified,
            phone,
            photoURL,
          });

          const childAccounts = await getChildAccounts(uid);
          setChildAccounts(childAccounts);

          if (childAccounts.length) {
            setContextChildAccount(childAccounts[0]);
          }
        } else {
          setLoggedInUser(null);
        }
        setWaitingForAuth(false);
      } catch (err) {
        logger.error("Error in onAuthStateChangedHook", { err });
      }
    });
  }, []);

  return waitingForAuth ? (
    <div className="w-100 d-flex" style={{ height: "100vh" }}>
      <div className="m-auto">
        <Loading size={10} opacity={0.6} />
      </div>
    </div>
  ) : (
    <div>
      <Router history={history}>
        <Switch>
          <Route exact path="/page-not-found">
            <PageNotFound />
          </Route>
          <Route exact path="/">
            <Welcome />
          </Route>
          <Route exact path="/lobby">
            {loggedInUser ? <Lobby /> : <Redirect to="/sign-in" />}
          </Route>
          {/* Sign in page should be available even if the user is already logged in*/}
          {/* This ensures that the user will be able to open verification email and land here even if logged in */}
          <Route path="/sign-in">
            <SignIn />
          </Route>
          <Route path="*">
            <Redirect to="/page-not-found" />
          </Route>
        </Switch>
      </Router>
    </div>
  );
};

const App: FunctionComponent = () => {
  return (
    <RecoilRoot>
      <WrappedApp />
    </RecoilRoot>
  );
};

export default App;
