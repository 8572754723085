import { Timestamp } from "@firebase/firestore";

export enum BANKS {
  IGUD = 13,
  OTSAR = 14,
  DISCOUNT = 11,
  POALIM = 12,
  YAHAV = 4,
  YERUSHALAIM = 54,
  LEUMI = 10,
  MIZRACHI = 20,
  MASAD = 46,
  FIBI = 31,
}

export type AccountDetails = {
  tzId?: string;
  bankId?: BANKS;
  bankName?: string;
  bankBranchId?: number;
  accountId?: number;
};

export type ChildAccount = {
  id?: string;
  firstParentUid: string;
  secondParentUid?: string;
  childNameByChild?: string;
  childNameByParent?: string;
  firstName?: string;
  lastName?: string;
  createdAt: Timestamp;
  updatedAt: Timestamp;
  isVerified: boolean;
  verifiedAt?: Timestamp;
  verificationCode?: number;
  verificationCodeExpiration?: Timestamp;
  bankAccount: AccountDetails;
};
