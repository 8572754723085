/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FunctionComponent, useEffect, useState } from "react";
import TextTransition, { presets } from "react-text-transition";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";

import {
  getChildAccount,
  getChildAccounts,
  updateChildAccountVerificationCode,
} from "../../services/firebase";

import {
  childAccountsAtom,
  contextChildAccountAtom,
} from "../../state/atoms/app";
import {
  showInviteChildToDownloadAppModalAtom,
  showLinkChildAccountOTPModalAtom,
} from "../../state/atoms/ui";
import { loggedInUserAtom } from "../../state/atoms/auth";
import progressIcon from "../../images/progress.svg";

const LinkChildAccountOTPModal: FunctionComponent = () => {
  const loggedInUser = useRecoilValue(loggedInUserAtom);

  const [showModal, setShowModal] = useRecoilState(
    showLinkChildAccountOTPModalAtom
  );
  const [contextChildAccount, setContextChildAccount] = useRecoilState(
    contextChildAccountAtom
  );
  const setShowInviteChildToDownloadAppModal = useSetRecoilState(
    showInviteChildToDownloadAppModalAtom
  );
  const setChildAccounts = useSetRecoilState(childAccountsAtom);
  const totalSeconds = 300;
  const [secondsLeft, setSecondsLeft] = useState(totalSeconds);

  const onClose = () => {
    setShowInviteChildToDownloadAppModal(true);
    setShowModal(false);
  };

  const { id, verificationCode } = contextChildAccount || {};

  const beautifyVerificationCode = function (code: string) {
    return `${code.substring(0, 2)}  ${code.substring(2, 4)}  ${code.substring(
      4,
      6
    )}`;
  };

  useEffect(() => {
    if (id) {
      const interval = setInterval(async () => {
        const updateVerificationCode = async () => {
          await updateChildAccountVerificationCode(id);
          const childAccount = await getChildAccount(id);
          setContextChildAccount(childAccount);
        };

        setSecondsLeft((previousState) => {
          if (previousState === 0) {
            updateVerificationCode();
          }
          return previousState > 0 ? previousState - 1 : totalSeconds;
        });
      }, 1000);

      if (!showModal) {
        clearInterval(interval);
      }

      return () => {
        interval && clearInterval(interval);
        setSecondsLeft(0);
      };
    }
  }, [id, showModal]);

  useEffect(() => {
    if (id) {
      const updateVerificationCode = async () => {
        if (!verificationCode) {
          await updateChildAccountVerificationCode(id);
          const childAccount = await getChildAccount(id);
          setContextChildAccount(childAccount);
        }
      };
      updateVerificationCode();
    }
  }, [id, verificationCode]);

  if (!id || !verificationCode || !loggedInUser) return <></>;

  const { uid } = loggedInUser;

  const minutes = `${Math.floor(secondsLeft / 60)}`.padStart(2, "0");
  const seconds = `${secondsLeft % 60}`.padStart(2, "0");
  const invertedProgressPercentage = 100 - (secondsLeft / totalSeconds) * 100;

  return (
    <div
      className={`modal ${showModal ? "show" : ""} fade bg-black bg-opacity-50`}
      role="button"
      onClick={() => setShowModal(false)}
    >
      <div
        className="modal-dialog modal-dialog-centered"
        role="button"
        onClick={(evt) => {
          evt.stopPropagation();
        }}
      >
        <div className="modal-content">
          <div className="form-group">
            <div className="modal-body p-4">
              <div className="text-center">
                <div>
                  <img src={progressIcon} alt="progress" />
                </div>
                <h4 className="modal-title mt-3 mb-1 font-weight-bold">
                  בקש מילדך לפתוח את אפליקציית ג&apos;וניור ולהקליד את הקוד
                  המוצג
                </h4>
              </div>
              <p className="mb-0">לא יכול לסיים את התהליך כרגע?</p>
              <p>ניתן להפיק קוד חדש בכל עת באתר שלנו</p>
              <div className="text-center text-jumbo">
                <TextTransition
                  className="m-auto w-7rem d-inline child-account-add-otp"
                  text={beautifyVerificationCode(verificationCode.toString())}
                  springConfig={presets.stiff}
                />
              </div>
              <div className="m-auto" style={{ width: "60%" }}>
                <div>
                  <div
                    className="progress bg-secondary"
                    style={{ height: "0.4rem" }}
                  >
                    <div
                      className="progress-bar bg-light"
                      role="progressbar"
                      style={{
                        width: `${invertedProgressPercentage}%`,
                        transition: "width 0.5s",
                      }}
                      aria-valuenow={invertedProgressPercentage}
                      aria-valuemin={0}
                      aria-valuemax={100}
                    />
                  </div>
                </div>
                <div className="text-center text-muted">
                  <small>
                    {minutes}:{seconds}
                  </small>
                </div>
              </div>
            </div>
            <div className="modal-footer p-4 d-block text-center">
              <button
                type="button"
                className="btn text-white bg-brand-primary w-75"
                onClick={async () => {
                  const childAccount = await getChildAccount(id);
                  if (childAccount) {
                    const childAccounts = await getChildAccounts(uid);
                    setChildAccounts(childAccounts);
                    setContextChildAccount(childAccount);
                    setShowModal(false);
                  }
                }}
              >
                אמשיך מאוחר יותר
              </button>
              <button
                className="btn text-brand-primary d-block m-auto"
                onClick={onClose}
              >
                אני רוצה לשלוח זימון לאפליקציה שוב
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LinkChildAccountOTPModal;
