export enum SharePeriods {
  ONE_MONTH,
  THREE_MONTHS,
  SIX_MONTHS,
  CUSTOM,
}

export type SignUpModalData = {
  name: string;
  phone: string;
  email: string;
  password: string;
  passwordConfirm: string;
};

export type SignInModalData = {
  email: string;
  password: string;
};

export type ErrorModalData = {
  title: string;
  description: string;
};

export type KidActivationData = {
  name: string;
  activationCode: number;
};
