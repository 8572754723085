/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FunctionComponent, useEffect, useState } from "react";
import { useRecoilState, useSetRecoilState, useRecoilValue } from "recoil";
import { isValidTzId } from "../../services/utils";
import { accountDetailsAtom } from "../../state/atoms/app";

import { loggedInUserAtom } from "../../state/atoms/auth";
import {
  showLinkChildAccountBankSelectionModalAtom,
  showLinkChildAccountBankConsentModalAtom,
} from "../../state/atoms/ui";
import { BANKS } from "../../types/account";
import Loading from "../Loading";

const LinkChildAccountBankSelectionModal: FunctionComponent = () => {
  const loggedInUser = useRecoilValue(loggedInUserAtom);
  const [showModal, setShowModal] = useRecoilState(
    showLinkChildAccountBankSelectionModalAtom
  );
  const [accountDetails, setAccountDetails] =
    useRecoilState(accountDetailsAtom);
  const setShowLinkChildAccountBankConsentModal = useSetRecoilState(
    showLinkChildAccountBankConsentModalAtom
  );
  const [loading, setLoading] = useState(false);
  const [canSubmit, setCanSubmit] = useState(false);

  const onClose = () => {
    setShowModal(false);
  };

  if (!loggedInUser) return <></>;

  const onSubmit = async () => {
    setLoading(true);
    setLoading(false);
    setShowModal(false);
    setShowLinkChildAccountBankConsentModal(true);
  };

  useEffect(() => {
    if (!accountDetails) {
      setCanSubmit(false);
    } else {
      const { tzId, bankBranchId, accountId } = accountDetails;
      setCanSubmit(!!(isValidTzId(tzId) && bankBranchId && accountId));
    }
  }, [accountDetails]);

  useEffect(() => {
    setAccountDetails({ bankId: BANKS.LEUMI });
  }, []);

  return (
    <div
      className={`modal ${showModal ? "show" : ""} fade bg-black bg-opacity-50`}
      role="button"
      onClick={onClose}
    >
      <div
        className="modal-dialog modal-dialog-centered"
        role="button"
        onClick={(evt) => {
          evt.stopPropagation();
        }}
      >
        <div className="modal-content">
          <div className="form-group">
            <div className="modal-header p-4">
              <h4 className="modal-title font-weight-bold">
                קישור חשבון הבנק לחשבון ג&apos;וניור
              </h4>
            </div>
            <div className="modal-body px-4 py-0">
              {loading ? (
                <div className="text-center py-3">
                  <Loading size={10} opacity={0.6} />
                </div>
              ) : (
                <>
                  <p>
                    על מנת שילדך יוכל לצפות בפעילות בחשבון הבנק שפתחת עבורו,
                    נבקש את אישורך לקבל גישה לחשבון זה. ללא הרשאה זו לא נוכל
                    להציג מידע באפליקציה.
                  </p>
                  <div className="mt-4">
                    <div className="form-group">
                      <div className="mb-2">
                        <label htmlFor="tzId" className="mt-1">
                          מספר תעודת זהות
                        </label>
                        <input
                          id="tzId"
                          className={`form-control`}
                          type="text"
                          onChange={(evt) => {
                            const tzId = evt.target.value;
                            setAccountDetails({
                              ...accountDetails,
                              tzId,
                            });
                          }}
                        />
                        <label htmlFor="bank" className="mt-1">
                          בנק
                        </label>
                        <div>
                          <select
                            id="inputState"
                            className="form-control"
                            defaultValue={BANKS.LEUMI}
                            onBlur={(evt) => {
                              const bankId = parseInt(evt.target.value);
                              setAccountDetails({
                                ...accountDetails,
                                bankId,
                              });
                            }}
                          >
                            <option value={BANKS.DISCOUNT}>בנק דיסקונט</option>
                            <option value={BANKS.LEUMI}>בנק לאומי</option>
                            <option value={BANKS.POALIM}>בנק הפועלים</option>
                            <option value={BANKS.YERUSHALAIM}>
                              בנק ירושלים
                            </option>
                            <option value={BANKS.YAHAV}>בנק יהב</option>
                            <option value={BANKS.OTSAR}>בנק אוצר החייל</option>
                            <option value={BANKS.MIZRACHI}>
                              בנק מזרחי טפחות
                            </option>
                            <option value={BANKS.FIBI}>
                              הבנק הבינלאומי הראשון
                            </option>
                            <option value={BANKS.MASAD}>בנק מסד</option>
                          </select>
                        </div>

                        <div className="row m-0 p-0">
                          <div className="col-12 col-md-6 p-0 pl-0 pl-md-05">
                            <label htmlFor="bankBranch" className="mt-1">
                              סניף
                            </label>
                            <input
                              id="bankBranch"
                              className={`form-control`}
                              type="text"
                              onChange={(evt) => {
                                const bankBranchId = parseInt(
                                  evt.target.value,
                                  10
                                );
                                setAccountDetails({
                                  ...accountDetails,
                                  bankBranchId,
                                });
                              }}
                            />
                          </div>
                          <div className="col-12 col-md-6 p-0 pr-0 pr-md-05">
                            <label htmlFor="bankAccountNo" className="mt-1">
                              מספר חשבון
                            </label>
                            <input
                              id="bankAccountNo"
                              className={`form-control`}
                              type="text"
                              onChange={(evt) => {
                                const accountId = parseInt(
                                  evt.target.value,
                                  10
                                );
                                setAccountDetails({
                                  ...accountDetails,
                                  accountId,
                                });
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="modal-footer p-4 d-block text-center">
                        <button
                          className="btn text-white bg-brand-primary w-75"
                          onClick={onSubmit}
                          disabled={!canSubmit}
                        >
                          המשך
                        </button>
                        <button
                          className="btn text-brand-primary d-block m-auto"
                          onClick={onClose}
                        >
                          אמשיך מאוחר יותר
                        </button>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LinkChildAccountBankSelectionModal;
