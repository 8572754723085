/* eslint-disable @typescript-eslint/no-explicit-any */
enum LOG_LEVELS {
  DEBUG = "DEBUG",
  INFO = "INFO",
  WARN = "WARN",
  ERROR = "ERROR",
}

const LOG_LEVELS_MAP = {
  DEBUG: 0,
  INFO: 1,
  WARN: 2,
  ERROR: 3,
};

const LOG_LEVEL =
  LOG_LEVELS_MAP[LOG_LEVELS[(process.env.LOG_LEVEL as LOG_LEVELS) || "DEBUG"]];

export const logger = {
  debug: (...args: any[]): void => {
    LOG_LEVEL <= LOG_LEVELS_MAP.DEBUG &&
      console.debug(`${new Date()} [DEBUG]: `, ...args);
  },
  info: (...args: any[]): void => {
    LOG_LEVEL <= LOG_LEVELS_MAP.INFO &&
      console.info(`${new Date()} [INFO]: `, ...args);
  },
  warn: (...args: any[]): void => {
    LOG_LEVEL <= LOG_LEVELS_MAP.WARN &&
      console.warn(`${new Date()} [WARN]: `, ...args);
  },
  error: (...args: any[]): void => {
    LOG_LEVEL <= LOG_LEVELS_MAP.ERROR &&
      console.error(`${new Date()} [ERROR]: `, ...args);
  },
};
