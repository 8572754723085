/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FunctionComponent, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useRecoilState } from "recoil";

import {
  sendUserEmailVerification,
  signUserUp,
  upsertUser,
} from "../../services/firebase";
import { logger } from "../../services/logger";
import {
  isValidEmail,
  isValidPassword,
  isValidPhoneNumber,
} from "../../services/utils";
import { signUpFormDataAtom } from "../../state/atoms/auth";
import { showSignUpModalAtom } from "../../state/atoms/ui";
import { AppUser } from "../../types/user";

const SignUpModal: FunctionComponent = () => {
  const history = useHistory();
  const [showModal, setShowModal] = useRecoilState(showSignUpModalAtom);
  const [signUpFormData, setSignUpFormData] =
    useRecoilState(signUpFormDataAtom);

  const { name, phone, email, password, passwordConfirm } = signUpFormData;

  const [canSubmit, setCanSubmit] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [errorEmailMsg, setErrorEmailMsg] = useState("");
  const [errorPhoneMsg, setErrorPhoneMsg] = useState("");
  const [errorPasswordMsg, setErrorPasswordMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");

  const onClose = () => {
    setShowModal(false);
  };

  const onSubmit = async () => {
    if (!canSubmit || submitting) return;
    try {
      setSubmitting(true);
      setErrorEmailMsg("");
      setErrorPasswordMsg("");
      setErrorMsg("");
      const { user } = await signUserUp(email.trim(), password);
      await sendUserEmailVerification(user);
      const { uid, photoURL } = user;
      const userToUpsert: AppUser = { uid, name, phone, email, photoURL };
      await upsertUser(userToUpsert);
      setSignUpFormData({
        name: "",
        phone: "",
        email: "",
        password: "",
        passwordConfirm: "",
      });
      setShowModal(false);
      history.push("/lobby");
    } catch (err) {
      const { code } = err as { code: string };
      logger.error("Sign up error", err);
      switch (code) {
        case "auth/email-already-in-use":
          setErrorEmailMsg("משתמש עם כתובת אימייל זו כבר רשום במערכת");
          break;
        case "auth/invalid-email":
          setErrorEmailMsg("כתובת האימייל אינה תקנית");
          break;
        case "auth/weak-password":
          setErrorPasswordMsg("הסיסמה אינה חזקה מספיק");
          break;
        default:
          setErrorMsg("הרישום נכשל, אנא ודא כי הזנת פרטים נכונים");
          break;
      }
    } finally {
      setSubmitting(false);
    }
  };

  useEffect(() => {
    setCanSubmit(
      !!(
        name &&
        isValidPhoneNumber(phone) &&
        isValidEmail(email) &&
        isValidPassword(password) &&
        password === passwordConfirm
      )
    );
  }, [name, phone, email, password, passwordConfirm]);

  useEffect(() => {
    if (email && !isValidEmail(email)) {
      setErrorEmailMsg("נא להזין כתובת האימייל תקנית");
    } else {
      setErrorEmailMsg("");
    }
  }, [email]);

  useEffect(() => {
    if (phone && !isValidPhoneNumber(phone)) {
      setErrorPhoneMsg("נא להזין מספר טלפון תקני");
    } else {
      setErrorPhoneMsg("");
    }
  }, [phone]);

  useEffect(() => {
    if (password && !isValidPassword(password)) {
      setErrorPasswordMsg("נא להזין סיסמה חזקה מספיק");
    } else {
      setErrorPasswordMsg("");
    }
  }, [password]);

  return (
    <div
      className={`modal ${showModal ? "show" : ""} fade bg-black bg-opacity-50`}
      role="button"
      onClick={onClose}
    >
      <div
        className="modal-dialog modal-dialog-centered"
        role="button"
        onClick={(evt) => {
          evt.stopPropagation();
        }}
      >
        <div className="modal-content">
          <form
            className="form-group"
            onSubmit={(evt) => {
              evt.preventDefault();
              onSubmit();
            }}
          >
            <div className="modal-header p-4">
              <h4 className="modal-title font-weight-bold">יצירת משתמש חדש</h4>
            </div>
            <div className="modal-body px-4 py-0">
              <label htmlFor="name">שמך המלא</label>
              <input
                id="name"
                className="form-control"
                type="text"
                name="name"
                autoComplete="name"
                value={name}
                onChange={(evt) =>
                  setSignUpFormData((previousState) => ({
                    ...previousState,
                    name: evt.target.value,
                  }))
                }
              />
              <label htmlFor="email" className="mt-1">
                האימייל שלך
              </label>
              <input
                id="email"
                className={`form-control ${
                  errorEmailMsg ? "border-danger" : ""
                }`}
                type="email"
                name="email"
                autoComplete="email"
                value={email}
                onChange={(evt) =>
                  setSignUpFormData((previousState) => ({
                    ...previousState,
                    email: evt.target.value,
                  }))
                }
              />
              {errorEmailMsg && (
                <small className="d-block mb-2 text-danger">
                  {errorEmailMsg}
                </small>
              )}
              <label htmlFor="phone" className="mt-1">
                הטלפון הנייד שלך
              </label>
              <input
                id="phone"
                className="form-control"
                type="tel"
                name="phone"
                autoComplete="tel-national"
                value={phone}
                onChange={(evt) =>
                  setSignUpFormData((previousState) => ({
                    ...previousState,
                    phone: evt.target.value,
                  }))
                }
              />
              {errorPhoneMsg && (
                <small className="d-block mb-2 text-danger">
                  {errorPhoneMsg}
                </small>
              )}
              <div className="text-black text-small font-weight-bold mt-2 mr-1">
                <div>הסיסמה מינימום 8 תווים עם</div>
                <div>שילוב של אות גדולה, אות קטנה וסימן</div>
              </div>
              <label htmlFor="password" className="mt-1">
                סיסמה
              </label>
              <input
                id="password"
                className={`form-control ${
                  errorPasswordMsg ? "border-danger" : ""
                }`}
                type="password"
                name="password"
                autoComplete="new-password"
                value={password}
                onChange={(evt) =>
                  setSignUpFormData((previousState) => ({
                    ...previousState,
                    password: evt.target.value,
                  }))
                }
              />
              {errorPasswordMsg && (
                <small className="d-block text-danger">
                  {errorPasswordMsg}
                </small>
              )}
              <label htmlFor="passwordConfirm" className="mt-1">
                הקלד שנית את הסיסמה
              </label>
              <input
                id="passwordConfirm"
                className={`form-control ${
                  errorPasswordMsg ? "border-danger" : ""
                }`}
                type="password"
                name="passwordConfirm"
                autoComplete="new-password"
                value={passwordConfirm}
                onChange={(evt) =>
                  setSignUpFormData((previousState) => ({
                    ...previousState,
                    passwordConfirm: evt.target.value,
                  }))
                }
              />
              {password && passwordConfirm && password !== passwordConfirm && (
                <small className="d-block text-danger">
                  הסיסמאות לא תואמות
                </small>
              )}
              {errorMsg && (
                <small className="d-block text-danger">{errorMsg}</small>
              )}
            </div>
            <div className="modal-footer p-4 d-block text-center">
              <button
                type="submit"
                className="btn text-white bg-brand-primary w-75"
                disabled={!canSubmit}
                onClick={onSubmit}
              >
                יצירת חשבון
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default SignUpModal;
