export const NODE_ENV = process.env.NODE_ENV;
export const BACKEND_BASE =
  process.env.REACT_APP_BACKEND_BASE || "http://localhost:3000";
export const API_VERSION = process.env.REACT_APP_API_VERSION || "v1";
export const REACT_APP_DOMAIN = process.env.REACT_APP_DOMAIN || "localhost";
export const BANK = "דיסקונט";
export const BANK_URL = "https://start.telebank.co.il/login/#/LOGIN_PAGE";
// "https://www.mizrahi-tefahot.co.il/login/bv5x83ut/index.html#/auth-page-he?aspxerrorpath=%2Flogin%2Flogin2MTO.aspx"
// "https://www.bankhapoalim.co.il/he/login"
// "https://www.fibi.co.il/wps/portal/"
